<template>
  <ul
    class="memberList"
    :class="`memberList--${site}`"
  >
    <li
      v-for="(item, idx) in allList"
      :key="item.mid"
    >
      <div class="memberList__imgCell">
        <div
          class="memberList__img"
          :style="imgStyle(item.img)"
        />
      </div>
      <div class="memberList__InfoCell">
        <p class="memberList__quantity">
          {{ terms.historyPoint + ": " + item.sum }}
        </p>
        <p class="memberList__name">
          {{ item.name }}
        </p>
      </div>
      <div class="memberList__btns">
        <button
          type="button"
          @click="checkCookieToken(idx)"
        >
          {{ terms.vote }}
        </button>
        <button
          type="button"
          @click="checkCookieToken(idx, true)"
        >
          {{ terms.donate }}
        </button>
      </div>
    </li>
  </ul>
</template>
<script>
import allMembersMixin from '@/mixins/allMembersMixin'

export default {
  mixins: [allMembersMixin]
}
</script>
<style lang="scss" scoped>
@import "../scss/vars";

.memberList {
  padding: 0;
  margin-bottom: 0;
  list-style-type: none;

  li {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  li + li {
    border-top: 1px solid $color-gold;
  }
  .memberList__imgCell {
    width: 72px;
  }
  .memberList__InfoCell {
    flex: 1;
    text-align: left;
    padding-left: 10px;
    padding-right: 10px;

    p {
      line-height: 1.2;
      margin-bottom: 0;
    }

    .memberList__quantity {
      font-size: 0.6rem;
    }
    .memberList__name {
      font-size: 0.8rem;
    }
  }
  .memberList__img {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    background: {
      color: $color-gray;
      image: url(../assets/avatar-default.svg);
      position: center center;
      repeat: no-repeat;
      size: cover;
    }
    overflow: hidden;
    border-radius: 50%;
  }
  button {
    display: block;
    border: none;
    background: {
      color: $color-gold;
      image: url(../assets/game-bt.jpg);
      position: center center;
      repeat: no-repeat;
      size: cover;
    }
    font-size: 0.6rem;
    color: $color-white;
    width: 81px;
    height: 0.9rem;
    line-height: 1;
    padding: 0;
    text-shadow: 1px 1px 1px $color-black, 1px 1px 1px $color-black,
      1px 1px 2px $color-black;
  }
  button + button {
    margin-top: 10px;
  }

  &.memberList--4 {
    button {
      background-image: url(../assets/btn-3.jpg);
    }
  }
}
</style>
