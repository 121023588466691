import { mapState } from 'vuex'
import TheLoadingRoller from '@/components/TheLoadingRoller'
import { EventBus } from '@/event-bus'

export default {
  name: 'Billboard',
  components: { TheLoadingRoller },
  props: {
    mode: { type: Number, required: true }
  },
  data () {
    return {
      currData: null
    }
  },
  computed: {
    ...mapState({
      loadStatus: function (state) {
        return this.currData ? this.currData.loadStatus : 0
      },
      all: function (state) {
        return this.currData ? this.currData.data : []
      },
      top3: function (state) {
        const list = this.currData ? this.currData.data : []
        return list.length <= 3 ? list : list.slice(0, 3)
      },
      site: (state) => state.site
    })
  },
  mounted () {
    EventBus.$on('refresh-list', this.getData)

    const stateNames = [
      'billboardWeek',
      'billboardMonth',
      'billboardLastMonth'
    ]
    this.currData = this.$store.state[stateNames[this.mode - 1]]
    this.getData()
  },
  beforeDestroy: function () {
    EventBus.$off('refresh-list', this.getData)
  },
  methods: {
    async getData () {
      try {
        const response = await this.$store.dispatch('getMembersSum', this.mode)
        const snap = response.data

        snap.sort((a, b) => {
          return b.sum - a.sum
        })
        snap.forEach((elem, idx, arr) => {
          elem.perc = arr[0].sum
            ? Math.round((elem.sum / arr[0].sum) * 100)
            : 0
        })
        this.$store.commit('SET_BILLBOARD', { mode: this.mode, snap })
      } catch (err) {
        EventBus.$emit('show-notice', { idx: 4, err: err.toString() })
      }
    },
    billboardImgStyle (img) {
      return img ? `background-image:url(${img})` : ''
    }
  }
}
