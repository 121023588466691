<template>
  <div
    class="billboardSet"
    :class="`billboardSet--${site}`"
  >
    <div class="topThree container-fluid">
      <div
        v-if="loadStatus == 1"
        class="row"
      >
        <TheLoadingRoller class="billboardSet__loading" />
      </div>
      <p
        v-else-if="top3.length == 0"
        class="topThree__noData"
      >
        暫無資料
      </p>
      <transition name="topThreeTransition">
        <ul
          v-if="loadStatus == 2 && top3.length > 0"
          class="topThree__list row"
        >
          <li
            v-for="(item, billbordIdx) in top3"
            :key="item.mid"
            class="topThree__listItem col col-4"
          >
            <p class="topThree__listItemNum">
              NO.{{ billbordIdx * 1 + 1 }}
            </p>
            <div
              class="topThree__listItemImg"
              :style="billboardImgStyle(item.img)"
            />
            <p class="topThree__listItemName">
              {{ item.name }}
            </p>
          </li>
        </ul>
      </transition>
    </div>
    <div
      v-if="loadStatus == 2 && all.length > 0"
      class="billboardSet__allList"
    >
      <table>
        <tr
          v-for="(item, billbordIdx) in all"
          :key="item.mid"
        >
          <th>NO.{{ billbordIdx * 1 + 1 }}&nbsp;{{ item.name }}</th>
          <td>
            <div
              class="bar"
              :style="`width: ${item.perc}%`"
            />
            <span>{{ item.sum }}</span>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
import billboardMixin from '@/mixins/billboardMixin'

export default {
  mixins: [billboardMixin]
}
</script>
<style lang="scss" scoped>
@import "../scss/vars";
$helfGutter-topThree: 10px;

.billboardSet__loading {
  margin: auto;
  margin: 10% auto;
}

.topThree__noData {
  padding: 80px 0;
}

.topThree.container-fluid {
  padding: 0;

  .row {
    margin-right: -$helfGutter-topThree;
    margin-left: -$helfGutter-topThree;
  }
  .col {
    padding-left: $helfGutter-topThree;
    padding-right: $helfGutter-topThree;
  }
}

.topThreeTransition-enter-active,
.topThreeTransition-leave-active {
  transition: margin 0.5s $specialBezier 0s, opacity 0.5s $specialBezier 0s;
}

.topThreeTransition-enter,
.topThreeTransition-leave-to {
  margin-top: 180px;
  opacity: 0;
}

.topThreeTransition-enter-to,
.topThreeTransition-leave {
  margin-top: 0;
  opacity: 1;
}

.topThree__list {
  list-style-type: none;
  padding: 0;
}
.topThree__listItem {
  text-align: center;
}
.topThree__listItemNum {
  line-height: 1;
  margin-bottom: 15px;
  font-size: 1rem;
  color: $color-yellow;
}
.topThree__listItemImg {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  background: {
    color: $color-gray;
    image: url(../assets/avatar-default.svg);
    position: center center;
    repeat: no-repeat;
    size: cover;
  }
  overflow: hidden;
  border-radius: 50%;
}
.topThree__listItemName {
  background-color: $color-gold;
  margin-top: 1.2rem;
  font-size: 0.7rem;
  line-height: 1.5;
  padding-left: 5px;
  padding-right: 5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

$barPaddingTopBottom: 3px;
$fz-listBar: 0.7rem;
.billboardSet__allList {
  border: 1px solid $color-gold;
  background-color: $color-black;
  padding: 10px;

  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 10px;

    th,
    td {
      padding-top: $barPaddingTopBottom;
      padding-bottom: $barPaddingTopBottom;
      font-size: $fz-listBar;
      line-height: $fz-listBar;
      text-align: left;
    }
    th {
      white-space: nowrap;
      width: 1%;
    }
    td {
      position: relative;
      padding-left: 0;

      .bar {
        background-color: $color-gold;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
      }

      span {
        position: absolute;
        left: 5px;
        top: $barPaddingTopBottom;
        text-align: left;
        display: inline-block;
        font-size: $fz-listBar;
        line-height: $fz-listBar;
      }
    }
    tr:nth-child(n + 4) td .bar {
      opacity: 0.45;
    }
  }
}

.billboardSet--4 {
  .topThree__listItemNum {
    text-shadow: 2px 2px 8px rgb(0, 0, 0), 1px 1px 1px rgba(0, 0, 0, 0.5);
  }

  .topThree__listItemName {
    background-color: $color-lightOrange;
  }

  .billboardSet__allList {
    border: 1px solid $color-lightOrange;

    table {
      td {
        .bar {
          background-color: $color-lightOrange;
        }
      }
    }
  }
}
</style>
