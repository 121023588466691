<template>
  <ul
    class="memberList"
    :class="`memberList--${site}`"
  >
    <li
      v-for="(item, idx) in allList"
      :key="item.mid"
    >
      <p class="memberList__name">
        {{ item.name }}
      </p>
      <div class="memberList__imgCell">
        <div
          class="memberList__img"
          :style="imgStyle(item.img)"
        />
      </div>
      <p class="memberList__quantity">
        {{ terms.historyPoint + ": " + item.sum }}
      </p>
      <button
        type="button"
        @click="checkCookieToken(idx)"
      >
        {{ terms.vote }}
        <!-- 我要評分 -->
      </button>
      <button
        type="button"
        @click="checkCookieToken(idx,true)"
      >
        {{ terms.donate }}
        <!-- 抖內打賞 -->
      </button>
    </li>
  </ul>
</template>
<script>
import allMembersMixin from '@/mixins/allMembersMixin'

export default {
  mixins: [allMembersMixin]
}
</script>
<style lang="scss" scoped>
@import "../scss/vars";

.memberList {
  padding: 0;
  margin-bottom: 0;
  list-style-type: none;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

  li {
    width: 33%;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
  }
  .memberList__name {
    font-size: 0.8rem;
    margin-bottom: 0.25rem;
  }
  .memberList__imgCell {
    width: 100px;
    max-width: 90%;
    margin: auto;
  }
  .memberList__img {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    background: {
      color: $color-gray;
      image: url(../assets/avatar-default.svg);
      position: center center;
      repeat: no-repeat;
      size: cover;
    }
    overflow: hidden;
    border-radius: 50%;
  }
  .memberList__quantity {
    font-size: 0.6rem;
    margin-bottom: 0;
    margin-top: 0.25rem;
  }
  button {
    border: none;
    background: {
      color: $color-gold;
      image: url(../assets/game-bt.jpg);
      position: center center;
      repeat: no-repeat;
      size: cover;
    }
    font-size: 0.6rem;
    color: $color-white;
    width: 81px;
    height: 0.9rem;
    line-height: 1;
    padding: 0;
    text-shadow: 1px 1px 1px $color-black, 1px 1px 1px $color-black,
      1px 1px 2px $color-black;
  }
}
</style>
