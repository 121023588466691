<template>
  <ul
    class="memberList"
    :class="`memberList--${site}`"
  >
    <li
      v-for="(item, idx) in allList"
      :key="item.mid"
    >
      <div class="memberList__imgCell">
        <div
          class="memberList__img"
          :style="imgStyle(item.img)"
        />
      </div>
      <div class="memberList__InfoCell">
        <p class="memberList__quantity">
          {{ terms.historyPoint + ": " + item.sum }}
        </p>
        <p class="memberList__name">
          {{ item.name }}
        </p>
      </div>

      <div class="memberList__btns">
        <button
          type="button"
          @click="checkCookieToken(idx)"
        >
          {{ terms.vote }}
          <!-- 我要評分 -->
        </button>
        <button
          type="button"
          @click="checkCookieToken(idx, true)"
        >
          {{ terms.donate }}
          <!-- 抖內打賞 -->
        </button>
      </div>
    </li>
  </ul>
</template>
<script>
import allMembersMixin from '@/mixins/allMembersMixin'

export default {
  mixins: [allMembersMixin]
}
</script>
<style lang="scss" scoped>
@import "../scss/vars";

.memberList {
  padding: 0;
  margin-bottom: 0;
  list-style-type: none;

  li {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  li + li {
    border-top: 1px solid #6fa4f7;
  }
  .memberList__imgCell {
    width: 72px;
  }
  .memberList__InfoCell {
    flex: 1;
    text-align: left;
    padding-left: 10px;
    padding-right: 10px;

    p {
      line-height: 1.2;
      margin-bottom: 0;
    }

    .memberList__quantity {
      font-size: 0.6rem;
      text-shadow: 0.5px -0.5px 0.5px $color-purple-site2,
        -0.5px -0.5px 0.5px $color-purple-site2,
        -0.5px 0.5px 0.5px $color-purple-site2,
        0.5px 0.5px 0.5px $color-purple-site2;
    }
    .memberList__name {
      font-size: 0.8rem;
    }
  }
  .memberList__img {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    background: {
      color: $color-gray;
      image: url(../assets/avatar-default.svg);
      position: center center;
      repeat: no-repeat;
      size: cover;
    }
    overflow: hidden;
    border-radius: 50%;
  }

  $w-btn: 80px;
  $w-btn-xxs: 70px;
  $w-btn-xxxs: 60px;
  .memberList__btns {
    width: $w-btn;
  }
  button {
    border: none;
    background: {
      color: transparent;
      image: url(../assets/gbet-btn.png);
      position: center center;
      repeat: no-repeat;
      size: contain;
    }
    font-size: 0.6rem;
    color: $color-white;
    width: $w-btn;
    height: 69px;
    line-height: 64px;
    padding: 0 0 5px 10px;
    text-align: left;
    text-shadow: 1px 1px 1px $color-black, 1px 1px 1px $color-black,
      1px 1px 2px $color-black;
  }

  @media (max-width: $maxW-xxs) {
    .memberList__btns {
      width: $w-btn-xxs;
    }
    button {
      width: $w-btn-xxs;
      height: 61px;
    line-height: 56px;
    padding: 0 0 5px 7px;
    }
  }
  @media (max-width: $maxW-xxxs) {
    .memberList__btns {
      width: $w-btn-xxxs;
    }
    button {
      width: $w-btn-xxxs;
      height: 52px;
    line-height: 48px;
    padding: 0 0 4px 5px;
    }
  }
}
</style>
