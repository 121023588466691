<template>
  <div
    class="donateDialog"
    :class="`donateDialog--${site}`"
  >
    <transition
      apear
      mode="out-in"
      name="formTransition"
      @after-leave="close()"
    >
      <div
        v-show="ready"
        class="wrapper"
      >
        <div class="donateDialogInner">
          <header>
            <h2>{{ terms.donateTo + donateMemberName }}</h2>
            <img
              v-if="donateMemberImg"
              class="avatarImg"
              :src="donateMemberImg"
              :alt="donateMemberName"
            >
          </header>
          <TheLoadingRoller
            v-show="sending"
            class="donateForm__loading"
          />
          <div
            v-show="notice"
            class="donateForm__notice"
          >
            <p>{{ notice }}</p>
            <button
              type="button"
              class="donateForm__btn"
              @click="transitionClose"
            >
              {{ terms.ok }}
            </button>
          </div>
          <form v-show="!sending && !notice">
            <div class="donateForm__item">
              <p class="donateForm__itemP">
                {{ siteName + " " + terms.account
                }}<!-- GS BET 帳號 -->
              </p>
            </div>
            <input
              id="account"
              v-model.trim="acc"
              type="text"
              class="donateForm__input"
            >
            <p
              v-show="showAccRequired"
              class="donateForm__requiredHint"
              :class="shakeHint"
            >
              * {{ terms.required }}
            </p>
            <div class="donateForm__item">
              <p class="donateForm__itemP">
                {{ siteName + " " + terms.phoneNumber
                }}<!-- 手機號碼 -->
              </p>
            </div>
            <input
              id="phone"
              v-model.trim="phone"
              type="text"
              class="donateForm__input"
              @keyup="phoneValidate()"
            >
            <p
              v-show="showPhoneInvalid"
              class="donateForm__requiredHint"
              :class="shakeHint"
            >
              * {{ phoneInvalidTerm }}
            </p>
            <div class="donateForm__item">
              <p class="donateForm__itemP">
                {{ terms.donation
                }}<!-- 打賞金額 -->
              </p>
            </div>
            <input
              id="donation"
              v-model.number="donation"
              type="number"
              class="donateForm__input"
            >
            <p
              v-show="showDonationInvalid"
              class="donateForm__requiredHint"
              :class="shakeHint"
            >
              * {{ donationInvalidTerm }}
            </p>
            <p class="donationForm__thx">
              {{ terms.thxDonation }}
            </p>
            <p class="donationForm__execTime">
              {{ terms.donationExecTime }}
            </p>
            <div class="donateForm__btnBlock">
              <button
                type="button"
                class="donateForm__btn donateForm__btn--cancel"
                @click="cancel()"
              >
                {{ terms.cancelDonate }}
              </button>
              <button
                type="button"
                class="donateForm__btn donateForm__btn--submit"
                @click="submit()"
              >
                {{ terms.submitRate }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { COOKIE_KIT } from '@/class/cookieKit'
import TheLoadingRoller from '@/components/TheLoadingRoller'
import { EventBus } from '@/event-bus'

export default {
  name: 'DonateDialog',
  components: {
    TheLoadingRoller
  },
  data () {
    return {
      ready: false,
      acc: '',
      phone: '',
      donation: '',
      sending: false,
      notice: '',
      shake: false,
      timeoutId: null,
      baseURL: ''
    }
  },
  computed: {
    donateMemberName: function () {
      return this.$store.state.donateMember.name
    },
    donateMemberMid: function () {
      return this.$store.state.donateMember.mid
    },
    donateMemberImg: function () {
      return this.$store.state.donateMember.img
    },
    showAccRequired: function () {
      return this.acc.trim() === ''
    },
    showPhoneInvalid: function () {
      if (this.phone.trim() === '') {
        return 1
      }
      const re = /^09\d{8}$/
      if (!re.test(this.phone)) {
        return 2
      }
      return 0
    },
    phoneInvalidTerm: function () {
      const t = ['', this.terms.required, this.terms.phoneFormatWrong]
      return t[this.showPhoneInvalid]
    },
    showDonationInvalid: function () {
      if (!this.donation) {
        return 1
      }
      const d = this.donation * 1
      if (d * 1 < 100 || d > 1000) {
        return 2
      }
      return 0
    },
    donationInvalidTerm: function () {
      const t = ['', this.terms.required, this.terms.donationLimit]
      return t[this.showDonationInvalid]
    },
    shakeHint: function () {
      return this.shake ? 'donateForm__requiredHint--shake' : ''
    },
    cssIdx () {
      const idxs = [null, 1, 1, 2, 3, '4-1']
      return idxs[this.site]
    },
    ...mapState({
      uid: (state) => state.uid,
      site: (state) => state.site,
      terms: (state) => state.terms,
      siteName: (state) => state.siteName
    })
  },
  mounted: function () {
    this.baseURL = process.env.BASE_URL
    this.ready = true

    if (this.$store.state.gradeAcc) {
      this.acc = this.$store.state.gradeAcc
    } else {
      const acc = COOKIE_KIT.getCookie('rateAcc')
      if (acc) {
        this.acc = acc
        this.$store.commit('SET_RATE_ACC', acc)
      }
    }
  },
  methods: {
    stopShake: function () {
      this.shake = false
      this.timeoutId = null
    },
    cancel: function () {
      this.ready = false
    },
    phoneValidate: function () {
      this.phone = this.phone.replace(/\D/g, '')
    },
    submit: async function () {
      if (this.showAccRequired) {
        this.shake = true
        if (this.timeoutId) {
          clearTimeout(this.timeoutId)
        }
        this.timeoutId = setTimeout(this.stopShake, 1100)
        return false
      }
      const acc = this.acc.trim()
      const phone = this.phone.trim()
      const donation = this.donation
      const opt = {}
      opt['max-age'] = 30 * 60 * 60 * 24
      COOKIE_KIT.setCookie('rateAcc', acc, opt)
      this.$store.commit('SET_RATE_ACC', acc)

      let token
      if (this.site === 5) {
        token = ''
      } else if (this.site === 1) {
        token = this.$store.state.fbToken
      } else {
        token = COOKIE_KIT.getCookie('la_token')
      }
      if (!(token && this.uid && this.donateMemberMid && acc)) {
        this.notice =
          '打賞失敗。缺少必要登入資料無法完成打賞。請重新整理，再試一次。'
      }
      try {
        this.sending = true

        const response = await this.$store.dispatch('donate', {
          token,
          userId: this.uid,
          mid: this.donateMemberMid,
          account: acc,
          phone,
          donation
        })
        this.sending = false

        const snap = response.data * 1
        console.log('snap', snap)

        const notices = [
          this.terms.donateSuccess,
          this.terms.donateFailed,
          this.terms.donateFailed,
          this.terms.donateFailed,
          this.terms.donateFailed,
          this.terms.donateFailed
        ]
        this.notice = notices[snap]
      } catch (err) {
        EventBus.$emit('show-notice', { idx: 5, err: err.toString() })
      }
    },
    transitionClose () {
      this.ready = false
    },
    close () {
      this.$store.commit('CANCEL_DONATE')
      this.$store.commit('FreezeBody', false)
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../scss/vars";

.wrapper {
  width: 80%;
  max-width: 400px;
  min-height: auto;
  max-height: 80%;
  margin: auto;
  padding: 0 0 20px;
  background: linear-gradient(to bottom, #444444df 0%, #222e 20%);
  // background: {
  //   color: transparent;
  //   image: url(../assets/bg-wrapper1.jpg);
  //   position: center 0;
  //   repeat: no-repeat;
  //   size: cover;
  // }
  border: 1px solid $color-gold;
  box-shadow: 5px 5px 5px #000c;
  overflow: auto;
}

h2 {
  font-size: 0.8rem;
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 10px;
  margin-bottom: 5px;
}

.donateDialog {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.75);
}

.formTransition-enter-active {
  transition: margin 0.5s $specialBezier, opacity 0.3s ease-in-out;
}
.formTransition-leave-active {
  transition: margin 0.5s $specialBezier-reverse, opacity 0.3s ease-in-out;
}

.formTransition-enter,
.formTransition-leave-to {
  opacity: 0;
  margin-top: -20rem;
}

.formTransition-enter-to,
.formTransition-leave {
  opacity: 1;
  margin-top: 0;
}

.donateDialogInner {
  padding: $pt-page 20px;

  header .avatarImg {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 5px;
  }

  @media (max-width: $maxW-xxs) {
    padding: $pt-page 12px 20px;
  }
}

.donateForm__loading {
  margin-top: 20%;
}
.donateForm__notice {
  font-size: 1.25rem;
  text-shadow: 1px 0 0 $color-black, 0 1px 0 $color-black,
    1px 1px 0 $color-black;
}
.donateForm__item {
  text-align: left;
}
.donateForm__itemP {
  display: inline-block;
  font-size: 0.7rem;
  background-color: #644e1f;
  text-align: left;
  margin-left: 0;
  margin-top: 10px;
  margin-bottom: 0;
  padding: 4px 10px;
  line-height: 1;
}

.donateForm__input {
  width: 100%;
  border: 1px solid $color-gold;
  background-color: $color-black;
  color: $color-white;
  font-size: 0.8rem;
  padding-left: 5px;
  padding-right: 0;
  line-height: 1.8;
}

.donateForm__requiredHint {
  font-size: 0.7rem;
  line-height: 1;
  margin-bottom: 5px;
  color: $color-red;
  text-align: left;
}
.donationForm__thx,
.donationForm__execTime {
  text-align: left;
  font-size: 0.8rem;
  margin-top: 20px;

  @media (max-width: $maxW-xxxs) {
    font-size: 0.6rem;
  }
}

@keyframes shakeHint {
  0% {
    transform: translate(0);
  }
  25% {
    transform: translate(-7px);
  }
  50% {
    transform: translate(0);
  }
  75% {
    transform: translate(7px);
  }
  100% {
    transform: translate(0);
  }
}
.donateForm__requiredHint--shake {
  animation: shakeHint 0.2s ease-in-out 0s infinite normal none;
}

$w-formBtn: 118px;
$h-formBtn: 26px;
.donateForm__btn {
  color: $color-white;
  width: $w-formBtn;
  height: $h-formBtn;
  font-weight: 500;
  background: {
    color: $color-gold;
    image: url(../assets/game-bt.jpg);
    position: center center;
    repeat: no-repeat;
    size: cover;
  }
  font-size: 0.9rem;
  line-height: 1;
  border: none;
  text-shadow: 1px 1px 1px $color-black, 1px 1px 1px $color-black,
    1px 1px 2px $color-black;

  &.donateForm__btn--cancel {
    background: {
      color: $color-gray;
      image: url(../assets/game-bt2.jpg);
    }
  }
  &.donateForm__btn--submit {
    margin-left: 5px;
  }
}

::placeholder {
  color: $color-gray;
}

.donateDialog--2 {
  .wrapper {
    background-image: url(../assets/bg-wrapper5.jpg);
    border: 1px solid #6fa4f7;
  }
  .h2WithBG {
    position: relative;
    width: 80%;
    height: 0;
    padding-bottom: 38%;
    margin: auto;
    margin-bottom: 20px;
    background: {
      image: url(../assets/logo5.png);
      position: center center;
      repeat: no-repeat;
      size: contain;
    }

    h2 {
      position: absolute;
      padding-top: 0;
      padding-bottom: 0;
      margin-top: 0;
      margin-bottom: 0;
      width: 100%;
      top: 50%;
      text-align: center;
      font-size: 1.1rem;
      text-shadow: 1px -1px 1px $color-purple-site2,
        -1px -1px 1px $color-purple-site2, -1px 1px 1px $color-purple-site2,
        1px 1px 1px $color-purple-site2;
    }
  }
  .avatarImg {
    margin-bottom: 20px;
    box-shadow: 3px 3px 0px #ac15ea;
  }
  .donateForm__input {
    border: 1px solid #6fa4f7;
    background: linear-gradient(to bottom, #c842db3d 10%, #00000005 60%);

    &::placeholder {
      color: darken($color-white, 10%);
    }
  }
  .donateForm__itemP {
    background: linear-gradient(to bottom, #fb4200 15%, #84182d 60%);
    border-radius: 5px;
    box-shadow: 0px 2px 0px #000000;
  }
  .donateForm__btn {
    background: {
      color: transparent;
      image: url(../assets/gbet-btn2.png);
    }
    height: 59px;
    padding-bottom: 2%;

    &.donateForm__btn--cancel {
      background: {
        color: transparent;
        image: url(../assets/gbet-btn3.png);
      }
    }
  }
}
.donateDialog--3 {
  .wrapper {
    background-image: url(../assets/bg-wrapper2.jpg);
  }
  .donateDialogInner {
    background: {
      color: transparent;
      image: url(../assets/bg-billboard2.png);
      position: center 0;
      repeat: no-repeat;
      size: 100% auto;
    }
  }

  .donateForm__item {
    text-align: center;
  }
  .donateForm__itemP {
    color: #f4ecd9;
    text-shadow: 0.7px 0.5px 0.3px #100b00;
    background: {
      color: transparent;
      image: url(../assets/billboardListLink2.png);
      position: center center;
      repeat: no-repeat;
      size: cover;
    }
  }
  .donateForm__input {
    margin-top: 0.2rem;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    border-image: linear-gradient(
        to left,
        $color-gold,
        rgba(0, 0, 0, 0),
        $color-gold
      )
      1/1px;
  }

  .donateForm__btn {
    background: {
      color: transparent;
      image: url(../assets/billboardListLink2.png);
      position: center center;
      repeat: no-repeat;
      size: cover;
    }
    text-shadow: none;

    &.donateForm__btn--cancel {
      background: {
        color: transparent;
        image: url(../assets/billboardListLink2.png);
      }
    }
  }
  ::placeholder {
    color: #7f7769;
  }
}
.donateDialog--4 {
  .wrapper {
    background-image: url(../assets/bg-wrapper3.jpg);
    border: 1px solid $color-lightOrange;
  }

  .donateForm__itemP {
    color: $color-black;
    background-color: $color-lightOrange;
  }
  .donateForm__input {
    border: 1px solid $color-lightOrange;
  }

  .donateForm__btn {
    background: {
      color: transparent;
      image: url(../assets/btn-3.jpg);
      position: center center;
      repeat: no-repeat;
      size: cover;
    }

    &.donateForm__btn--cancel {
      background: {
        color: transparent;
        image: url(../assets/btn-3.jpg);
      }
    }
  }
}

.donateDialog--5 {
  .wrapper {
    background-image: url(../assets/bg-wrapper4.jpg);
    border: 1px solid $color-white;
  }
  .donateDialogInner {
    padding: 40px;
  }
  h2 {
    font-size: 0.9rem;
    font-weight: bold;
    text-shadow: 1px 1px 2px lighten($color-blue-5, 10%),
      -1px -1px 2px lighten($color-blue-5, 10%),
      -1px 1px 2px lighten($color-blue-5, 10%),
      1px -1px 2px lighten($color-blue-5, 10%);
  }
  .donateForm__item {
    text-align: center;
    margin-bottom: 5px;
  }
  .donateForm__itemP {
    background-color: transparent;
    font-weight: bold;
    font-size: 0.8rem;
    text-shadow: 1px 1px 2px lighten($color-blue-5, 10%),
      -1px -1px 2px lighten($color-blue-5, 10%),
      -1px 1px 2px lighten($color-blue-5, 10%),
      1px -1px 2px lighten($color-blue-5, 10%);
  }
  .donateForm__input {
    border: 1px solid $color-white;
    box-shadow: 0 0 3px 2px lighten($color-blue-5, 10%);
  }

  .donateForm__btnBlock {
    margin-top: 20px;
  }
  .donateForm__btn {
    background: {
      color: transparent;
      image: url(../assets/btn-4.jpg);
      position: center center;
      repeat: no-repeat;
      size: cover;
    }

    &.donateForm__btn--cancel {
      background: {
        color: transparent;
        image: url(../assets/btn-4.jpg);
      }
    }
  }

  @media (max-width: $maxW-xxs) {
    .donateDialogInner {
      padding: 20px;
    }
  }
}
</style>
