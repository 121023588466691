<template>
  <div
    class="billboardSet"
    :class="`billboardSet--${site}`"
  >
    <div class="topThree container-fluid">
      <div
        v-if="loadStatus == 1"
        class="row"
      >
        <TheLoadingRoller class="billboardSet__loading" />
      </div>
      <p
        v-else-if="top3.length == 0"
        class="topThree__noData"
      >
        暫無資料
      </p>
      <transition name="topThreeTransition">
        <ul
          v-if="loadStatus == 2 && top3.length > 0"
          class="topThree__list row"
        >
          <li
            v-for="(item, billbordIdx) in top3"
            :key="item.mid"
            class="topThree__listItem col col-4"
          >
            <div class="topThree__listItemImgSet">
              <div class="topThree__listItemImgWrapper">
                <div
                  class="topThree__listItemImg"
                  :style="billboardImgStyle(item.img)"
                />
              </div>
            </div>
            <p class="topThree__listItemNum">
              NO.{{ billbordIdx * 1 + 1 }}
            </p>
            <p class="topThree__listItemName">
              <span>{{ item.name + " / " + item.sum }}</span>
            </p>
          </li>
        </ul>
      </transition>
    </div>
    <div
      v-if="loadStatus == 2 && all.length > 0"
      class="billboardSet__allList"
    >
      <table>
        <tr
          v-for="(item, billbordIdx) in all"
          :key="item.mid"
        >
          <th>
            <div class="billboardSet__allListAvatarWrapper">
              <div
                class="billboardSet__allListAvatar"
                :style="billboardImgStyle(item.img)"
              />
            </div>
          </th>
          <td>
            <div>
              <span
                class="billboardSet__num"
              >NO.{{ billbordIdx * 1 + 4 }}</span>
              <span>{{ item.name + " / " + item.sum }}</span>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import billboardMixin from '@/mixins/billboardMixin'

export default {
  mixins: [billboardMixin],
  computed: {
    ...mapState({
      all: function (state) {
        return this.currData &&
          this.currData.data &&
          this.currData.data.length > 3
          ? this.currData.data.slice(3)
          : []
      }
    })
  }
}
</script>
<style lang="scss" scoped>
@import "../scss/vars";
$helfGutter-topThree: 10px;
$helfGutter-topThree-xxxs: 5px;

.billboardSet__loading {
  margin: auto;
  margin: 10% auto;
}

.topThree__noData {
  padding: 80px 0;
}

.topThree.container-fluid {
  padding: 0;

  .row {
    margin-right: -$helfGutter-topThree;
    margin-left: -$helfGutter-topThree;
  }
  .col {
    padding-left: $helfGutter-topThree;
    padding-right: $helfGutter-topThree;
  }
  @media (max-width: $maxW-xxs) {
    .col {
      padding-left: $helfGutter-topThree-xxxs;
      padding-right: $helfGutter-topThree-xxxs;
    }
  }
}

.topThreeTransition-enter-active,
.topThreeTransition-leave-active {
  transition: margin 0.5s $specialBezier 0s, opacity 0.5s $specialBezier 0s;
}

.topThreeTransition-enter,
.topThreeTransition-leave-to {
  margin-top: 180px;
  opacity: 0;
}

.topThreeTransition-enter-to,
.topThreeTransition-leave {
  margin-top: 0;
  opacity: 1;
}

.topThree__list {
  list-style-type: none;
  padding: 0;
}

$skew: 10deg;
$sz-diamond: 40px;
$sz-diamond-xxs: 30px;
.topThree__listItem {
  text-align: center;
  &:nth-child(1) {
    order: 2;
  }
  &:nth-child(2) {
    order: 1;
  }
  &:nth-child(3) {
    order: 3;
  }
  &:nth-child(2),
  &:nth-child(3) {
    transform: scale(0.9);
    .topThree__listItemImgSet {
      margin: 20px auto 25px;
    }
    .topThree__listItemNum {
      margin-bottom: 15px;
    }
  }

  &:nth-child(2) {
    .topThree__listItemImgSet:after {
      background-image: url(../assets/diamond5_2.png);
    }
  }
  &:nth-child(3) {
    .topThree__listItemImgSet:after {
      background-image: url(../assets/diamond5_3.png);
    }
  }
  .topThree__listItemNameBr {
    display: none;
  }

  @media (max-width: $maxW-xxs) {
    .topThree__listItemImgSet {
      &:after {
        bottom: -$sz-diamond-xxs / 2;
        right: -$sz-diamond-xxs / 2;
        width: $sz-diamond-xxs;
        height: $sz-diamond-xxs;
      }
    }
    .topThree__listItemNameBr {
      display: block;
    }
    .topThree__listItemNameSlash {
      display: none;
    }
  }
}
.topThree__listItemNum {
  line-height: 1;
  margin-bottom: 10px;
  font-size: 1.2rem;
  color: $color-white;
  font-weight: bold;
  text-shadow: 2px 2px 8px lighten($color-blue-5, 10%),
    -2px -2px 8px lighten($color-blue-5, 10%),
    -2px 2px 8px lighten($color-blue-5, 10%),
    2px -2px 8px lighten($color-blue-5, 10%);
  @media (max-width: $maxW-xxs) {
    font-size: 0.9rem;
  }
}
.topThree__listItemImgSet {
  margin: 0 auto 15px;
  width: 80%;
  height: 0;
  padding-bottom: 80%;
  position: relative;

  &:after {
    content: "";
    display: block;
    position: absolute;
    bottom: -$sz-diamond / 2;
    right: -$sz-diamond / 2;
    width: $sz-diamond;
    height: $sz-diamond;
    background: {
      color: transparent;
      image: url(../assets/diamond5_1.png);
      position: center center;
      repeat: no-repeat;
      size: contain;
    }
  }
}
.topThree__listItemImgWrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  transform: skewX(-$skew);
  overflow: hidden;
  box-shadow: 8px 8px 0 0 $color-blue-5;
}
.topThree__listItemImg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -7%;
  right: -7%;
  margin: auto;
  background: {
    color: $color-gray;
    image: url(../assets/avatar-default.svg);
    position: center center;
    repeat: no-repeat;
    size: cover;
  }
  transform: skewX($skew);
}
.topThree__listItemName {
  display: inline-block;
  background-color: transparent;
  margin-top: 0.5rem;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.5;
  padding-left: 5px;
  padding-right: 5px;
  text-shadow: 2px 2px 4px lighten($color-blue-5, 10%),
    -2px -2px 4px lighten($color-blue-5, 10%),
    -2px 2px 4px lighten($color-blue-5, 10%),
    2px -2px 4px lighten($color-blue-5, 10%), 4px 4px 2px $color-black;
  margin-bottom: 0;
  position: relative;
  span {
    position: relative;
    z-index: 3;
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    transform: skewX(-$skew);
    left: 3px;
    right: -5px;
    bottom: -4px;
    background-color: $color-blue-5;
    height: 24px;
    z-index: 2;
    box-shadow: 4px 4px 0 0 $color-lightBlue-5;
  }

  @media (max-width: $maxW-xxs) {
    font-size: 0.8rem;
  }
  @media (max-width: $maxW-xxxs) {
    font-size: 0.7rem;
  }
}

$barPaddingTopBottom: 3px;
$fz-listBar: 0.7rem;
.billboardSet__allList {
  padding: 10px 15%;

  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 10px;

    th,
    td {
      padding-top: $barPaddingTopBottom;
      padding-bottom: $barPaddingTopBottom;
      font-size: $fz-listBar;
      line-height: $fz-listBar;
      text-align: center;
      color: #f4ecd9;
      text-shadow: 0.7px 0.5px 0.3px #100b00;
    }
    th {
      white-space: nowrap;
      width: 1%;

      &:nth-child(1) {
        width: 1%;
        white-space: nowrap;
      }
    }
    td {
      text-align: left;
      vertical-align: top;
      font-size: 1rem;
      font-weight: bold;
      padding-left: 20px;

      span {
        position: relative;
        z-index: 3;
        text-shadow: 2px 2px 4px lighten($color-blue-5, 10%),
          -2px -2px 4px lighten($color-blue-5, 10%),
          -2px 2px 4px lighten($color-blue-5, 10%),
          2px -2px 4px lighten($color-blue-5, 10%), 4px 4px 2px $color-black;
      }
      div {
        // display: inline-block;
        position: relative;
        height: 100%;
        &:before {
          position: absolute;
          content: "";
          display: inline-block;
          right: 0;
          left: 15px;
          height: 26px;
          bottom: -8px;
          background-color: rgba(36, 61, 125, 0.75);
          z-index: 2;
          transform: skewX(-$skew);
          box-shadow: 4px 4px 4px 0 rgba(41, 189, 249, 0.75);
        }
      }
    }
    .billboardSet__num {
      display: inline-block;
      text-align: left;
      width: 50px;
      padding: 5px 0;
      margin-right: 15px;
    }
    .billboardSet__allListAvatarWrapper {
      display: inline-block;
      position: relative;
      width: 40px;
      height: 40px;
      margin: auto;
      overflow: hidden;
      transform: skewX(-$skew);
      box-shadow: 4px 4px 0 0 $color-blue-5;
    }
    .billboardSet__allListAvatar {
      position: absolute;
      left: -7%;
      right: -7%;
      top: 0;
      bottom: 0;
      margin: auto;
      transform: skewX($skew);
      background: {
        color: transparent;
        image: url(../assets/avatar-default.svg);
        position: center center;
        repeat: no-repeat;
        size: cover;
      }
    }
  }
  @media (max-width: $maxW-xxs) {
    padding: 0 10%;
    table {
      border-spacing: 5px;
      td {
        font-size: 0.7rem;
      }
      .billboardSet__num {
        padding: 5px 10px;
        margin-right: 10px;
      }
      .billboardSet__allListAvatarSet {
        width: 40px;
        height: 40px;
      }
    }
  }
  @media (max-width: $maxW-xxxs) {
    padding: 0 5%;
    .billboardSet__rateDeco {
      display: none;
    }
  }
}
</style>
