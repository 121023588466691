<template>
  <ul
    class="memberList"
    :class="`memberList--${site}`"
  >
    <li
      v-for="(item, idx) in allList"
      :key="item.mid"
    >
      <div class="memberList__imgCell">
        <div
          class="memberList__img"
          :style="imgStyle(item.img)"
        />
      </div>
      <div class="memberList__InfoCell">
        <p class="memberList__quantity">
          {{ terms.historyPoint + ": " + item.sum }}
        </p>
        <p class="memberList__name">
          {{ item.name }}
        </p>
      </div>
      <div class="memberList__btns">
        <button
          type="button"
          @click="checkCookieToken(idx)"
        >
          {{ terms.vote }}
        </button>
        <button
          type="button"
          @click="checkCookieToken(idx, true)"
        >
          {{ terms.donate }}
        </button>
      </div>
    </li>
  </ul>
</template>
<script>
import allMembersMixin from '@/mixins/allMembersMixin'

export default {
  mixins: [allMembersMixin]
}
</script>
<style lang="scss" scoped>
@import "../scss/vars";

$sz-img: 60px;
$skew: 10deg;
.memberList {
  padding: 0;
  margin-bottom: 0;
  list-style-type: none;

  li {
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: flex-end;
    padding-top: 20px;
    padding-bottom: 20px;

    &:before {
      content: "";
      display: block;
      position: absolute;
      z-index: 0;
      top: 30px;
      bottom: 0px;
      left: 30px;
      right: 10px;
      border: 1px solid #fff;
      margin: auto;
      transform: skewX(-10deg);
      background-color: rgba(0, 0, 0, 0.5);
      box-shadow: 0 0 5px 2px rgba(41, 189, 249, 0.75);
    }
  }
  li + li {
    // border-top: none;
  }
  .memberList__imgCell {
    position: relative;
    width: $sz-img;
    height: $sz-img;
    overflow: hidden;
    transform: skewX(-$skew);
    box-shadow: 4px 4px 0 0 #3555ac;
  }
  .memberList__img {
    position: absolute;
    left: -7%;
    right: -7%;
    top: 0;
    bottom: 0;
    margin: auto;
    transform: skewX($skew);
    background: {
      color: $color-gray;
      image: url(../assets/avatar-default.svg);
      position: center center;
      repeat: no-repeat;
      size: cover;
    }
  }
  .memberList__InfoCell {
    flex: 1;
    text-align: left;
    padding-left: 20px;
    padding-right: 10px;
    position: relative;

    p {
      line-height: 1.2;
      margin-bottom: 0;
    }

    .memberList__quantity {
      font-size: 0.6rem;
      padding-left: 5px;
    }
    .memberList__name {
      font-size: 1rem;
      font-weight: bold;
      text-shadow: 2px 2px 4px lighten($color-blue-5, 10%),
        -2px -2px 4px lighten($color-blue-5, 10%),
        -2px 2px 4px lighten($color-blue-5, 10%),
        2px -2px 4px lighten($color-blue-5, 10%);
    }
  }
  .memberList__btns {
    margin-bottom: -5px;
  }
  button {
    display: block;
    position: relative;
    border: none;
    background: {
      color: $color-gold;
      image: url(../assets/btn-4.jpg);
      position: center center;
      repeat: no-repeat;
      size: cover;
    }
    font-size: 0.6rem;
    color: $color-white;
    width: 81px;
    height: 0.9rem;
    line-height: 1;
    padding: 0;
    text-shadow: 1px 1px 1px $color-black, 1px 1px 1px $color-black,
      1px 1px 2px $color-black;
  }
  button + button {
    margin-top: 8px;
  }

  @media (max-width: $maxW-xxxs) {
    .memberList__InfoCell {
      padding-left: 10px;
      padding-right: 0;
    }
  }
}
</style>
